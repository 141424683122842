import React, { useRef, useContext } from "react"
import { useActions, LangCtx, useContextState } from "@components/contexted"
import { withHtml } from "@components/logic"
import { Layout } from "@components/layout"
import { BlogHero, Recommendations, CommentSection } from "@components/blog"
import {
  Breadcrumbs,
  ResizedImage,
  AnimatedLink,
  Modal,
  AnimatedButton,
  AnimatedSection,
} from "@components/shared"
import { DirectionArrows } from "@components/theme"
import {
  post,
  post__wrapper,
  post__content__wrapper,
  post__content__wrapper__text,
  post__wrapper__description,
  post__wrapper__description__date,
  post__content__wrapper__container__tags,
  circle,
} from "./styles/post.module.scss"
import LeavesSVG from "./icons/leaves.svg"
import CallendarSVG from "./icons/callendar.svg"
import dayjs from "dayjs"
const cleanPrice = (price) => {
  return parseFloat(price.replace(/[$,]+/g, ""))
}
function truncateString(str, num) {
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + "..."
}
const Description = withHtml("p")
const Tags = ({ tags }) => {
  return (
    <div className={post__content__wrapper__container__tags}>
      {tags.nodes.map(({ slug, name }, i) => {
        return (
          <AnimatedLink to={`/tags/${slug}/`} key={i}>
            {"#" + name}
          </AnimatedLink>
        )
      })}
    </div>
  )
}
const Title = withHtml("h1")
const Content = withHtml("article")
const linkModifier = ({ slug }) => `/blog/${slug}/`

const Post = ({ pageContext }) => {
  const { page, posts, nextPost, prevPost, products } = pageContext
  const { featuredImage, date, tags, translations } = page
  const { language } = useContextState(LangCtx, ["language"])
  const translationUrls = {
    pl: `/pl/blog/${translations[0].slug}/`,
    en: `/en/blog/${translations[0].slug}/`,
  }
  let clean = page.content.replace(/<\/?[^>]+(>|$)/g, "")
  return (
    <Layout {...page} translationUrl={translationUrls}>
      <div className={circle} />
      <div className={post}>
        <div
          className={post__wrapper}
          style={{ backgroundImage: `url(${featuredImage.node.sourceUrl})` }}
        >
          <div className={post__wrapper__description}>
            <Title>{page.title}</Title>
            <p>{truncateString(clean, 140)}</p>
            <div className={post__wrapper__description__date}>
              <img src={CallendarSVG} />
              {dayjs(date).format("DD.MM.YYYY")}
            </div>
          </div>
        </div>
        <div className={post__content__wrapper}>
          {/* <div className={post__content__wrapper__container}>
            <Tags tags={tags} />
          </div> */}
          <img src={LeavesSVG} />
          <div className={post__content__wrapper__text}>
            <Content>{page.content}</Content>
          </div>
          <DirectionArrows
            prevItem={prevPost}
            nextItem={nextPost}
            linkModifier={linkModifier}
            lang={language}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Post
