import React from "react"
import PropTypes from "prop-types"

import { AnimatedLink } from "@components/shared"
import { withHtml } from "@components/logic"

import {
  arrows,
  arrows__right,
  arrows__left,
  images,
  images__image,
} from "./arrows.module.scss"
import Prev from "./prev.svg"
const arrowRight = (
  <div>
    <i class="fas fa-caret-right"></i>
  </div>
)

const Label = withHtml()

const DirectionArrows = ({
  nextItem,
  prevItem,
  nextCategory,
  prevCategory,
  lang,
  linkModifier = ({ slug }) => `/${slug}/`,
  buttons = true,
}) => {
  if (!nextCategory || !prevCategory) {
    return (
      <div className={arrows}>
        <AnimatedLink
          className={arrows__left}
          to={`/${lang}/blog/${nextItem.slug}`}
        >
          <img loading="lazy" src={Prev} />
          <Label style={{ maxWidth: "250px" }}>{nextItem.title}</Label>
        </AnimatedLink>
        <AnimatedLink
          className={arrows__right}
          to={`/${lang}/blog/${nextItem.slug}`}
        >
          <Label style={{ maxWidth: "250px" }}>{prevItem.title + " "}</Label>
          <img loading="lazy" src={Prev} />
        </AnimatedLink>
      </div>
    )
  }
  return (
    <React.Fragment>
      <AnimatedLink>
        <Label>{nextItem.title}</Label>
      </AnimatedLink>
      <AnimatedLink>
        <Label>{prevItem.title}</Label>
      </AnimatedLink>
      <AnimatedLink>nextCategory</AnimatedLink>
      <AnimatedLink>prevCategory</AnimatedLink>
    </React.Fragment>
  )
}

DirectionArrows.propTypes = {
  nextItem: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
  }),
  prevItem: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
  }),
  nextCategory: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  prevCategory: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
}

export default DirectionArrows
