import React, { useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  Modal,
  TextInput,
  AnimatedLink,
  AnimatedButton,
} from "@components/shared"
import { withHtml } from "@components/logic"

import product from "./img/shopping-cart (1).png"
import post from "./img/speech-bubble.png"
import page from "./img/unstructured-information.png"

import {
  search,
  search__form,
  search__form__icon,
  search__list,
  search__item,
  search__item__link,
  search__noelements,
  search__wrapp,
  search__wrapp__icon,
} from "./search.module.scss"

const findInString = (str, val) => str.toLowerCase().includes(val.toLowerCase())

const H6 = withHtml("h6")

const SearchListElem = ({ title, slug, type }) => {
  if (type === "product") {
    return (
      <AnimatedLink to={`/shop/products/${slug}/`} className={search__item}>
        <div className={search__item__link}>
          <img src={product} alt="" />
          <H6>{title}</H6>
        </div>
      </AnimatedLink>
    )
  }
  if (type === "post") {
    return (
      <AnimatedLink to={`/blog/${slug}/`} className={search__item}>
        <div className={search__item__link}>
          <img src={post} alt="" />
          <H6>{title}</H6>
        </div>
      </AnimatedLink>
    )
  }
  return (
    <AnimatedLink to={`/${slug}/`} className={search__item}>
      <div className={search__item__link}>
        <img src={page} alt="" />
        <H6>{title}</H6>
      </div>
    </AnimatedLink>
  )
}

const Search = ({ className }) => {
  const [state, setState] = useState({ phrase: "", filtered: [] })
  const modal = useRef()
  const { WP } = useStaticQuery(graphql`
    query {
      WP {
        posts(first: 1000) {
          nodes {
            id
            slug
            title(format: RENDERED)
            content(format: RENDERED)
          }
        }
        products(first: 1000) {
          nodes {
            id
            slug
            name
            description
            shortDescription(format: RENDERED)
            status
          }
        }
        pages(first: 1000) {
          nodes {
            id
            title(format: RENDERED)
            slug
            content(format: RENDERED)
          }
        }
      }
    }
  `)
  const { posts, products, pages } = WP
  const publishedProducts = products.nodes.filter(
    (el) => el.status === "publish"
  )
  const _posts = posts.nodes.map((el) => {
    return {
      id: el.id,
      title: el.title,
      type: "post",
      slug: el.slug,
      content: el.content,
    }
  })

  const _products = publishedProducts.map((el) => {
    return {
      id: el.id,
      title: el.name,
      slug: el.slug,
      type: "product",
      content: el.description + " " + el.shortDescription,
    }
  })

  const _pages = pages.nodes.map((el) => {
    return {
      id: el.id,
      title: el.title,
      slug: el.slug,
      type: "page",
      content: el.content || "",
    }
  })

  const all = [..._products, ..._posts, ..._pages]

  const placeholder =
    all.length === 0 ? "..." : <i className="fas fa-search"></i>

  const setPhrase = (ev) => {
    let phrase = ev.target.value
    let _filtered = []
    if (phrase.length >= 2) {
      _filtered = all.filter((el) => {
        return [
          findInString(el.title, phrase),
          findInString(el.slug, phrase),
          findInString(el.content, phrase),
        ].includes(true)
      })
    }
    setState({
      phrase: ev.target.value,
      filtered: _filtered,
    })
  }

  return (
    <div className={search}>
      <AnimatedButton
        className={className}
        onClick={() => {
          modal.current.toggle()
        }}
      >
        {placeholder}
      </AnimatedButton>
      <Modal ref={modal} title={"Znajdź to co Cię interesuje"}>
        <div className={search__wrapp}>
          <div className={search__wrapp__icon}></div>
          <div className={search__form}>
            <form
              onSubmit={(ev) => {
                ev.preventDefault()
              }}
            >
              <TextInput
                autofocus={true}
                value={state.phrase}
                onChange={(ev) => {
                  setState({
                    phrase: ev.target.value,
                  })
                  setPhrase(ev)
                }}
                placeholder={"Wyszukaj na stronie"}
              />
              <div className={search__form__icon}>
                <i class="fas fa-search"></i>
              </div>
            </form>
          </div>
          <ul className={search__list}>
            {state.filtered.length === 0 && (
              <div className={search__noelements}>Brak szukanych elementów</div>
            )}
            {state.filtered.map((el, k) => {
              return <SearchListElem {...el} key={k} />
            })}
          </ul>
        </div>
      </Modal>
    </div>
  )
}

export default Search
